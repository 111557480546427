<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <dashboard-skeleton :page-loading="loading">
      <div class="back--wrapper hide--in-desktop hide--in-tablet">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('dashboard.favorite') }}
      </div>
      <div class="skeleton--container">
        <favorites-header />
        <favorites />
      </div>
    </dashboard-skeleton>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import DashboardSkeleton from '@/components/dashboard/dashboard-skeleton';
import FavoritesHeader from '@/components/dashboard/favorites/projects-header';
import Favorites from '@/components/dashboard/favorites/projects';
import DefaultHeadMixin from '@/mixins/defaultHead';
import { mapGetters, mapState } from 'vuex';

// const Pagination = () => import('@/components/utils/fractal-pagination.vue');
export default {
  mixins: [DefaultHeadMixin],
  components: {
    // Pagination,
    DefaultLayout,
    FavoritesHeader,
    Favorites,
    DashboardSkeleton,
  },
  data() {
    return {
      checkBankAccountDone: false,
    };
  },
  computed: {
    ...mapState({
      selectedListingType: (state) => state.myShortlist.selectedListingType,
    }),
    ...mapGetters({
      // favoritesActiveSortBy: 'myProject/activeSortBy',
      // favoritesCategories: 'myProject/allowedCategory',
      listingType: 'myShortlist/listingType',
    }),
    loading: {
      get() {
        return this.$store.state.myShortlist.loading;
      },
      set(value) {
        this.$store.commit('myShortlist/SET_LOADING', value);
      },
    },
    breadcrumbs() {
      const currentName = this.$t('dashboard.favorite');
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: currentName,
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
  watch: {
    selectedListingType() {
      this.getFavorites();
    },
  },
  methods: {
    getFavorites() {
      try {
        this.$store.dispatch('v2/search/getShortlistUuids', {
          listingType: this.listingType,
        });
        this.$store.dispatch('myShortlist/getMyShortlists', {
          listingType: this.listingType,
        });
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
    close() {
      this.$store.commit('myProject/SET_ACTIVE_SIDEBAR', false);
    },
  },
  mounted() {
    this.$store.commit('myProject/SET_ACTIVE_SIDEBAR', true);
    if (this.$store.state.global.loggedIn) {
      this.$store.commit('myShortlist/RESTORE_INITIAL_STATE');
      this.getFavorites();
    }
  },
};
</script>
