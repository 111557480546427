<template>
  <section class="my--project-header">
    <div class="category--container hide--in-mobile">
      <!--      <router-link class="btn btn-primary&#45;&#45;outline" to="/dashboard/myproject">-->
      <!--        {{ $t('dashboard.myProject') }}-->
      <!--      </router-link>-->
      <!--      <router-link class="btn btn-primary&#45;&#45;outline" to="/dashboard/mybooking">-->
      <!--        {{ $t('dashboard.myBooking') }}-->
      <!--      </router-link>-->
      <router-link class="btn btn-primary" to="/favorites">
        {{ $t('dashboard.favorite') }}
      </router-link>
    </div>
    <div class="d-flex">
      <v-select
        v-model="selectedListingType"
        :items="listingTypes"
        :label="$t('general.filter.listingType')"
        item-value="id"
        item-text="name"
        solo
        hide-details
        return-object
      ></v-select>
    </div>
  </section>
</template>

<script>
// import SearchProject from '@/components/dashboard/my-project/search-project';
import { mapState, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapState({
      sortBys: (state) => state.myProject.sortByNavList,
      listingTypes: (state) => state.v2.masters.listingTypes,
    }),
    ...mapGetters({
      categories: 'myProject/allowedCategory',
      activeSortBy: 'myProject/activeSortBy',
      activeCategoryObject: 'myProject/activeCategoryObject',
    }),
    selectedListingType: {
      get() {
        return this.$store.state.myShortlist.selectedListingType;
      },
      set(value) {
        this.$store.commit('myShortlist/SET_SELECTED_LISTING_TYPE', value);
      },
    },
  },
};
</script>
