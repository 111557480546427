<template>
  <section style="width: 100%">
    <div
      class="my--project-projects mt-4"
      v-if="selectedListingType && selectedListingType.id === 1"
    >
      <div v-for="project in shortlists" :key="project.uuid" class="favorite--project-item">
        <card-project :project="project" :show-compare="false" :allowMultiImage="$mq !== 'xs'" />
      </div>
    </div>
    <div
      class="my--project-projects mt-4"
      v-else-if="selectedListingType && selectedListingType.id === 2"
    >
      <div v-for="listing in shortlists" :key="listing.uuid" class="favorite--project-item">
        <card-listing :listing="listing" :allowMultiImage="$mq !== 'xs'" />
      </div>
    </div>
  </section>
</template>

<script>
const CardProject = () => import('@/components/utils/card-project');
const CardListing = () => import('@/components/utils/card-listing');
import { mapGetters, mapState } from 'vuex';

export default {
  components: {
    CardProject,
    CardListing,
  },
  data() {
    return {
      projects: [],
    };
  },
  computed: {
    ...mapState({
      selectedListingType: (state) => state.myShortlist.selectedListingType,
    }),
    ...mapGetters({
      shortlists: 'myShortlist/projects',
    }),
    ...mapState({
      shortlists: (state) => state.myShortlist.projects,
      isLoading: (state) => state.global.isLoading,
    }),
  },
};
</script>
