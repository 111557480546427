<template>
  <div>
    <div class="dashboard--skeleton">
      <page-loading :show="pageLoading" />
      <div class="left--col">
        <profile />
      </div>
      <div class="right--col" :class="{ active: isActive }">
        <slot />
      </div>
    </div>
    <!--    <dashboard-offer />-->
  </div>
</template>

<script>
import Profile from '@/components/dashboard/profile';
const PageLoading = () => import('@/components/content-loading/page-loading');
import { mapState } from 'vuex';

export default {
  name: 'dashboard-skeleton',
  components: {
    Profile,
    PageLoading,
  },
  props: ['pageLoading'],
  computed: {
    ...mapState({
      isActive: (state) => state.myProject.activeSidebar,
    }),
  },
};
</script>
