<template>
  <div>
    <section class="dashboard--profile">
      <img
        class="profile--picture"
        :src="!user ? '/img/placeholder.png' : user.photo_profile_user"
      />
      <div class="name">
        {{ !user ? '' : user.name }}
      </div>
      <!--    <div class="join">-->
      <!--      Bergabung sejak 21 September 2020-->
      <!--    </div>-->
      <router-link to="/profile" class="btn btn-primary">
        {{ $t('home.sidebarMenu.profile') }}
      </router-link>
      <!--    <button class="btn btn-primary&#45;&#45;light">-->
      <!--      Perjanjian Digital-->
      <!--    </button>-->
    </section>
    <div class="dashboard--profile-links hide--in-desktop hide--in-tablet">
      <!--      <div class="select&#45;&#45;item" @click="openPage('myProject')">-->
      <!--        {{ $t('dashboard.myProject') }}-->
      <!--      </div>-->
      <!--      <div class="select&#45;&#45;item" @click="openPage('myBooking')">-->
      <!--        {{ $t('dashboard.myBooking') }}-->
      <!--      </div>-->
      <div class="select--item" @click="openPage('favorite')">
        {{ $t('dashboard.favorite') }}
      </div>
      <div class="select--item" @click="logout">
        {{ $t('home.sidebarMenu.logout') }}
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/mixins/auth.js';
import { mapState } from 'vuex';

export default {
  name: 'profile-dashboard',
  mixins: [auth],
  computed: {
    ...mapState({
      user: (state) => state.global.user,
    }),
  },
  methods: {
    openPage(pageName) {
      switch (pageName) {
        case 'myProject': {
          if (this.$route.name === 'my-project')
            this.$store.commit('myProject/SET_ACTIVE_SIDEBAR', true);
          else this.$router.push('/dashboard/myproject');
          break;
        }
        case 'myBooking': {
          if (this.$route.name === 'my-booking')
            this.$store.commit('myProject/SET_ACTIVE_SIDEBAR', true);
          else this.$router.push('/dashboard/mybooking');
          break;
        }
        case 'favorite': {
          if (this.$route.name === 'favorites')
            this.$store.commit('myProject/SET_ACTIVE_SIDEBAR', true);
          else this.$router.push('/favorites');
          break;
        }
      }
    },
  },
};
</script>
